var submitted = false;
$(document)
  // to prevent form from submitting upon successful validation
  .on("submit", function(ev) {
    if(ev.target.id == "form-evaluatie"){
      if(submitted == true){
        ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
      }else{
        submitted = true;
        console.log("Submitted");
        // Your text from a form field, converted to lower case
        var textFieldValueLower = document.getElementById('open_belangstelling_in_training').value.toLowerCase();

        // Array of words you want to check against the text, also converted to lower case
        var positiveWords = ['ja', 'wel', 'misschien'].map(word => word.toLowerCase());
        var negativeWords = ['nee', 'niet'].map(word => word.toLowerCase());

        // Check if the text contains any word from the array, case-insensitively
        var containsPositive = positiveWords.some(word => textFieldValueLower.includes(word));
        var containsNegative = negativeWords.some(word => textFieldValueLower.includes(word));

        // Use the result as needed
        if (containsPositive) {
          dataLayer.push({'interestedInMore': true});
        }else if(containsNegative){
          dataLayer.push({'interestedInMore': false});
        }else{
          dataLayer.push({'interestedInMore': 'unknown'});
        }
        dataLayer.push({'event': 'EvaluatieIngevuld'});
      }
    }
  });

$('form #training').on('change', function(){
    if($(this).val() != "0"){
        $('.hidebydefault').show();
    }else{
        $('.hidebydefault').hide();

    }
});

document.addEventListener("DOMContentLoaded", () => {
    const input = document.getElementById("tag-input");
    const autocompleteList = document.getElementById("autocomplete-list");
    const selectedTagsContainer = document.getElementById("selected-tags");
    const hiddenTagsInput = document.getElementById("hidden-tags");

    const selectedTags = new Set();
    let debounceTimeout;

    // Function to update hidden input field
    const updateHiddenInput = () => {
        if (hiddenTagsInput) {
            hiddenTagsInput.value = Array.from(selectedTags).join(",");
        }
    };

    // Function to add a tag
    const addTag = (tag) => {
        tag = tag.trim();
        if (tag && !selectedTags.has(tag)) {
            selectedTags.add(tag);

            if (selectedTagsContainer) {
                // Create a visual tag element
                const tagElement = document.createElement("div");
                tagElement.className = "tag";
                tagElement.innerHTML = `${tag} <span class="remove">x</span>`;

                tagElement.querySelector(".remove").addEventListener("click", () => {
                    selectedTags.delete(tag);
                    selectedTagsContainer.removeChild(tagElement);
                    updateHiddenInput();
                });

                selectedTagsContainer.appendChild(tagElement);
            }

            updateHiddenInput();
        }
    };

    if (input) {
        // Handle input changes with debounce
        input.addEventListener("input", () => {
            const query = input.value.trim();
            clearTimeout(debounceTimeout);

            debounceTimeout = setTimeout(async () => {
                if (query.length > 0) {
                    try {
                        const response = await fetch(`/zoeken/autocomplete?q=${query}`);
                        const suggestions = await response.json();

                        autocompleteList.innerHTML = ""; // Clear old suggestions
                        suggestions.forEach((tag) => {
                            const li = document.createElement("li");
                            li.textContent = tag["value"];
                            li.addEventListener("click", () => {
                                addTag(tag["value"]);
                                input.value = "";
                                autocompleteList.innerHTML = "";
                            });
                            autocompleteList.appendChild(li);
                        });
                    } catch (error) {
                        console.error("Error fetching autocomplete suggestions:", error);
                    }
                } else {
                    autocompleteList.innerHTML = "";
                }
            }, 300);
        });

        // Add custom tag on Enter, Tab, or blur
        input.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.key === "Tab") {
                event.preventDefault();
                addTag(input.value);
                input.value = "";
                autocompleteList.innerHTML = "";
            }
        });
    }
});


document.addEventListener("DOMContentLoaded", () => {
    const sliders = document.querySelectorAll(".tb-slider");
    if(sliders){
        sliders.forEach((slider) => {
            // Create the tooltip element
            const tooltip = document.createElement("div");
            tooltip.className = "range-tooltip";
            tooltip.style.position = "absolute";
            tooltip.style.display = "none";
            tooltip.style.background = "#333";
            tooltip.style.color = "#fff";
            tooltip.style.padding = "5px 10px";
            tooltip.style.borderRadius = "5px";
            tooltip.style.fontSize = "12px";
            tooltip.style.pointerEvents = "none";
            tooltip.style.zIndex = "10";
            tooltip.style.whiteSpace = "nowrap";

            // Append the tooltip to the document body
            document.body.appendChild(tooltip);

            // Update tooltip position and value
            const updateTooltip = () => {
                const sliderRect = slider.getBoundingClientRect();
                tooltip.textContent = slider.value;

                // Position tooltip
                const tooltipWidth = tooltip.offsetWidth;
                const tooltipHeight = tooltip.offsetHeight;
                const handlePosition =
                    (slider.offsetWidth * (slider.value - slider.min)) / (slider.max - slider.min);

                // Adjust for scrolling and position above the slider handle
                const tooltipLeft = sliderRect.left + handlePosition - tooltipWidth / 2;
                const tooltipTop = sliderRect.top + window.scrollY - tooltipHeight - 5; // Adjust for scroll

                tooltip.style.left = `${tooltipLeft}px`;
                tooltip.style.top = `${tooltipTop}px`;
                tooltip.style.display = "block";
                // Verberg tooltip na 1000ms
                clearTimeout(tooltip.hideTimeout); // Voorkom dat oude timers blijven lopen
                tooltip.hideTimeout = setTimeout(() => {
                    tooltip.style.display = "none";
                }, 1000);
            };

            // Add event listeners
            slider.addEventListener("input", updateTooltip);
            slider.addEventListener("mousemove", updateTooltip);
            slider.addEventListener("mouseleave", () => {
                tooltip.style.display = "none";
            });
            // add event listener for touch devices
            slider.addEventListener("touchmove", updateTooltip);
            slider.addEventListener("touchend", () => {
                tooltip.style.display = "none";
            });
        });
    }
});

function updateSliderTrack(slider) {
    const min = slider.min || 0;
    const max = slider.max || 100;
    const value = slider.value;
    const percentage = ((value - min) / (max - min)) * 100;

    // Update background with gradient
    slider.style.background = `linear-gradient(to right, #007bff ${percentage}%, #ddd ${percentage}%)`;
}

function updateSliderVisuals(slider) {
    const value = parseInt(slider.value, 10);
    const markers = slider.closest('.slider-container').querySelectorAll('.marker');

    markers.forEach(marker => {
        const markerValue = parseInt(marker.querySelector('.denotation').textContent, 10);

        if (markerValue <= value) {
            marker.style.backgroundColor = "#007bff"; // Blue text
            marker.querySelector('.denotation').style.color = "#007bff"; // Blue circle
        } else {
            marker.style.backgroundColor = "#dbdbdb"; // Gray text
            marker.querySelector('.denotation').style.color = "#1e225d"; // Gray circle
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    // Select all sliders and attach markers
    const sliders = document.querySelectorAll(".tb-slider");

    if(sliders){
        sliders.forEach(slider => {
            const markersContainer = slider.nextElementSibling; // ".markers"
            const min = parseInt(slider.min)-1;
            const max = parseInt(slider.max) ;
            const step = parseInt(slider.step);
            const totalSteps = (max - min) / step;

            // Create markers dynamically
            for (let i = 0; i < totalSteps; i++) {
                const marker = document.createElement("div");
                marker.classList.add("marker");
                if(i == totalSteps-1){
                    //marker.style.right = `0%`; // Position marker

                }else{
                    //marker.style.left = `${(i / totalSteps) * 100+2}%`; // Position marker

                }

                const label = document.createElement("span");
                label.classList.add("other"); // Add the class 
                //label.textContent = value; // Add marker value
                marker.appendChild(label);

                const denotation = document.createElement("span");
                denotation.classList.add("denotation"); // Add the class "denotation"
                denotation.textContent = i+1; // Set the content to 1
                marker.appendChild(denotation);
                // Add click event to change slider value
                marker.addEventListener("click", () => {
                    slider.value = i+1;
                    slider.dispatchEvent(new Event("input")); // Trigger input event to update visuals
                });
                markersContainer.appendChild(marker);
            }

            //slider.addEventListener('input', () => updateSliderTrack(slider));
            slider.addEventListener('input', () => {
                updateSliderVisuals(slider);
                updateSliderTrack(slider);
            });

            //updateSliderTrack(slider); // Set initial value
            // Set initial state for both functions
            updateSliderVisuals(slider);
            updateSliderTrack(slider);
        });
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const form = document.getElementById("form-evaluatie");
    const steps = document.querySelectorAll(".form-step");
    const progressTrackerContainer = document.querySelector(".progress-tracker");
    const progressTracker = document.querySelectorAll(".progress-tracker .step");
    const prevBtn = document.getElementById("prev-btn");
    const nextBtn = document.getElementById("next-btn");
    const submitBtn = document.getElementById("submit-btn");

    let currentStep = 0; // Initialize the current step
  
    // Function to update step visibility and button states
    const updateSteps = () => {
        if(steps){
            steps.forEach((step, index) => {
                step.style.display = index === currentStep ? "block" : "none";
            });

            if(progressTracker){
                /*progressTracker.forEach((trackerStep, index) => {
                    trackerStep.classList.toggle("active", index === currentStep);
                });*/
                progressTracker.forEach((trackerStep, index) => {
                    if (trackerStep.dataset.step === "5") {
                        trackerStep.classList.toggle("active", currentStep === steps.length - 1);
                    } else if(trackerStep.dataset.step === "4"){
                        trackerStep.classList.toggle("active", currentStep >= index && currentStep < steps.length - 1);

                    } else {
                        trackerStep.classList.toggle("active", index === currentStep);
                    }
                });
            }

            if(prevBtn){
                prevBtn.style.display = currentStep > 0 ? "inline-block" : "none";
            }
            if(nextBtn){
                nextBtn.style.display = currentStep < steps.length - 1 ? "inline-block" : "none";
            }
            if(submitBtn){
                submitBtn.style.display = currentStep === steps.length - 1 ? "inline-block" : "none";
            }
            if (window.innerWidth <= 768 && currentStep > 0 ) {
                window.scrollTo({ top: progressTrackerContainer.offsetTop-100, behavior: "smooth" });
            }
        }
    };
    if(nextBtn){
        // Handle Next button click
        nextBtn.addEventListener("click", () => {
            if (currentStep < steps.length - 1) currentStep++;
            updateSteps();
        });
    }
    if(prevBtn){
        // Handle Previous button click
        prevBtn.addEventListener("click", () => {
            if (currentStep > 0) currentStep--;
            updateSteps();
        });
    }
    if(steps){
        updateSteps(); // Initialize the view
    }

    form.addEventListener("submit", function (event) {
        let firstInvalidStep = null;

        steps.forEach((step, index) => {
            const requiredFields = step.querySelectorAll("[required]");
            let stepHasError = false;

            requiredFields.forEach(field => {
                if (
                    (field.type === "checkbox" && !field.checked) ||
                    (field.type === "radio" && !document.querySelector(`input[name="${field.name}"]:checked`)) ||
                    (field.type !== "checkbox" && field.type !== "radio" && !field.value.trim())
                ) {
                    stepHasError = true;
                    field.classList.add("error");
                } else {
                    field.classList.remove("error");
                }
            });

            if (stepHasError && firstInvalidStep === null) {
                firstInvalidStep = index;
            }
        });

        if (firstInvalidStep !== null) {
            event.preventDefault(); // Stop het verzenden
            jumpToStep(firstInvalidStep);
        }
    });

    function jumpToStep(stepIndex) {
        console.log('Stap'+stepIndex);
        steps.forEach((step, index) => {
            step.style.display = index === stepIndex ? "block" : "none";
        });

        progressTracker.forEach((trackerStep, index) => {
            trackerStep.classList.toggle("active", index === stepIndex);
        });

        window.scrollTo({ top: steps[stepIndex].offsetTop, behavior: "smooth" });

        if(prevBtn){
            prevBtn.style.display = stepIndex > 0 ? "inline-block" : "none";
        }
        if(nextBtn){
            nextBtn.style.display = stepIndex < steps.length - 1 ? "inline-block" : "none";
        }
        if(submitBtn){
            submitBtn.style.display = stepIndex === steps.length - 1 ? "inline-block" : "none";
        }
    }
});
